import React, { useState } from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import { Link } from 'gatsby';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/Section/Section';
import SimpleModernHeadline from '../../components/SimpleModernHeadline/SimpleModernHeadline';
import HeadlineWithImage from '../../components/HeadlineWithImage/HeadlineWithImage';

import img_leistung_4 from '../../images/leistungen/img4.jpg';

const LeistungenPage = () => (
  <Layout headerWhite>
    <SEO title="Hyperhidrosis | Dr. K. Kostka in Bochum" description="Dr. K. Kostka bietet Hyperhidrosis mitten in Bochum zur Reduzierung der Schweisbildung an. Moderne Praxis ✓ Top Ausstattung ✓ Vereinbaren Sie jetzt einen Termin ✓"/>
    <Section>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={12}>
            <HeadlineWithImage headline={"Hyperhidrosis"} subtitle={"Endlich weniger schwitzen"} frontImage={img_leistung_4}></HeadlineWithImage>
          </Col>
        </Row>
        <p>
          Schwitzen ist ein natürlicher, lebensnotwendiger Prozess der Wärmeregulation des Körpers.
          Die Hyperhidrose bezeichnet ein unkontrollierbares, übermäßiges Schwitzen. Diese Fehlfunktion betrifft vorrangig die Schweißdrüsen von Achselhöhlen und Handinnenflächen/ Fußsohlen.
          <br/>
          <br/>
          Da die Schweißproduktion nerval gesteuert wird, kann eine Behandlung mit Botox© diese sehr effektiv reduzieren. Botox© hemmt die Reizübertragung von der  Nervenzelle zur Schweißdrüse. Lassen Sie sich von einer Expertin für Botox© Behandlungen direkt in der Bochumer Innenstadt beraten. Vereinbaren Sie dazu gerne telefonisch oder per Mail einen Termin mit mir.
          <br/>
          <br/>
          Die Injektionen erfolgen mit einer sehr feinen Nadel unter die Haut in den Achselhöhlen und sind kaum schmerzhaft. Der maximale Effekt tritt erst nach einigen Tagen ein. Das Ergebnis hält durchschnittlich 6 bis 12 Monate an. Um die Wirkung zu erhalten, sollte dementsprechend nach dieser Zeit eine Auffrischung erfolgen.              
        </p>
      </Container>
    </Section>
  </Layout>
);

export default LeistungenPage;
