import React from 'react';

import styles from './HeadlineWithImage.module.scss';

const Headline = ({
  children, headline, subtitle, frontImage
}) => (
  <div className={[styles.headlineContainer].join(' ')}>
    <div className={[styles.imageContainer].join(' ')} style={{backgroundImage: "url(" + frontImage + ")"}}>
    </div>
    <div className={[styles.contentContainer].join(' ')}>
        <h1 className={[styles.h1hwi].join(' ')}>{headline}</h1>
        <div className={[styles.seperator].join(' ')}></div>
        <h2 className={[styles.h2hwi].join(' ')}>{subtitle}</h2>
    </div>
    
    
  </div>
);

export default Headline;
