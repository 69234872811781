import React from 'react';
import PropTypes from 'prop-types';

import styles from './SimpleModernHeadline.module.scss';
import { prototype } from 'react-recaptcha';

const Headline = ({
  children, h1, h2, h3, center
}) => (
    <div className={[styles.simpleheadline, center ? styles.center : null].join(' ')}>
        {h1 && <h1>{children}</h1>}
        {h2 && <h2>{children}</h2>}
        {h3 && <h3>{children}</h3>}
  </div>
);

Headline.propTypes = {
  children: PropTypes.string,
  h1: PropTypes.bool,
  h2: PropTypes.bool,
  h3: PropTypes.bool,
  center: PropTypes.bool
};

Headline.defaultProps = {
  children: '',
  h1: false,
  h2: false,
  h3: false,
  center: false
};

export default Headline;
